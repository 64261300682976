import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Avatar, Box, Button, ButtonGroup, Center, Container, Flex, HStack, IconButton, Link, Menu,
  MenuButton,
  MenuItem,
  MenuList, Spacer,
  Spinner, Stat, StatArrow, StatGroup, StatHelpText, StatLabel,
  StatNumber
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FaTwitter } from 'react-icons/fa';
import './App.css';
import {
  sort, STARS_ASC, STARS_COUNT_CHANGE_ASC, STARS_COUNT_CHANGE_DESC,
  STARS_DESC, WATCHERS_ASC, WATCHERS_COUNT_CHANGE_ASC,
  WATCHERS_COUNT_CHANGE_DESC, WATCHERS_DESC
} from './sortOptions';
import { supabase } from './supabaseClient';

const App = () => {

  const [state, setState] = useState({});
  const [initialLoad, setInitialLoad] = useState(false);
  const [techMap] = useState(new Map());
  const [sortOrder, setSortOrder] = useState(STARS_DESC);


  useEffect(() => {
    async function fetchData() {
      const { data } = await supabase
        .from('github_view')
        .select(`
        technology_id, display_name, logo_url, stargazers_count, 
        watchers_count, stargazers_change_24h, watchers_change_24h`);

      if (data) {
        const newState = {};

        for (const technology of data) {
          techMap.set(technology.technology_id, technology.display_name);
          newState[`${technology.display_name}`] = {
            logo_url: technology.logo_url,
            github_stats: {
              stargazers_count: technology.stargazers_count,
              watchers_count: technology.watchers_count,
              stargazers_count_change: technology.stargazers_change_24h,
              watchers_count_change: technology.watchers_change_24h
            }
          }
        }

        setState(newState);
        setSortOrder(STARS_DESC);
        setInitialLoad(() => true);
      }
    }

    fetchData();
  }, [initialLoad, techMap]);

  if (!initialLoad) {
    return (
      <Flex width={'100vw'} height={"100vh"} flexDirection={'column'} alignContent={'center'} justifyContent={'center'}>
        <Spinner
          thickness='4px'
          speed='0.65s'
          emptyColor='gray.200'
          color='blue.500'
          size='xl'
          margin='0 auto'
        />
      </Flex>
    )
  }

  return (
    <Flex flexDirection={'column'}>
      <Box as="section">
        <Box as="nav" bg="bg-surface">
          <Container py={{ base: '4', lg: '5' }}>
            <HStack spacing="10" justify="space-between">
              <Flex justify="space-between" flex="1">
                <Spacer />
                <HStack spacing="3">
                  <Link
                    href="https://workingsoftware.dev"
                    target="_blank">About</Link>
                  <IconButton
                    as="a"
                    href="https://twitter.com/frontendstats"
                    target="_blank"
                    variant={'ghost'}
                    aria-label="Twitter"
                    icon={<FaTwitter fontSize="1.25rem" />}
                  />
                </HStack>
              </Flex>
            </HStack>
          </Container>
        </Box>
      </Box>
      <Container maxWidth='xl'>
        <ButtonGroup variant="link" spacing="8" mt='10px'>
          <Menu autoSelect={false} w='50%'>
            <MenuButton ml='5px' variant="ghost" size='sm' as={Button} rightIcon={<ChevronDownIcon />}>
              {sortOrder.sortText}
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => setSortOrder(STARS_DESC)}>
                <span>{STARS_DESC.sortText}</span>
              </MenuItem>
              <MenuItem onClick={() => setSortOrder(WATCHERS_DESC)}>
                <span>{WATCHERS_DESC.sortText}</span>
              </MenuItem>
              <MenuItem onClick={() => setSortOrder(STARS_COUNT_CHANGE_DESC)}>
                <span>{STARS_COUNT_CHANGE_DESC.sortText}</span>
              </MenuItem>
              <MenuItem onClick={() => setSortOrder(WATCHERS_COUNT_CHANGE_DESC)}>
                <span>{WATCHERS_COUNT_CHANGE_DESC.sortText}</span>
              </MenuItem>
              <MenuItem onClick={() => setSortOrder(STARS_ASC)}>
                <span>{STARS_ASC.sortText}</span>
              </MenuItem>
              <MenuItem onClick={() => setSortOrder(STARS_COUNT_CHANGE_ASC)}>
                <span>{STARS_COUNT_CHANGE_ASC.sortText}</span>
              </MenuItem>

              <MenuItem onClick={() => setSortOrder(WATCHERS_ASC)}>
                <span>{WATCHERS_ASC.sortText}</span>
              </MenuItem>
              <MenuItem onClick={() => setSortOrder(WATCHERS_COUNT_CHANGE_ASC)}>
                <span>{WATCHERS_COUNT_CHANGE_ASC.sortText}</span>
              </MenuItem>
            </MenuList>
          </Menu>
        </ButtonGroup>

        {Array.from(techMap)
          .sort((a, b) => sort(a, b, sortOrder, state))
          .map((entry) => {
            return (<Framework key={entry[0]} logo_url={state[entry[1]].logo_url} display_name={entry[1]} github_stats={state[entry[1]].github_stats}></Framework>);
          })}
      </Container>
    </Flex>
  );
};

const Framework = (props) => {

  const getStatArrow = (change) => {
    if (change > 0) {
      return <StatArrow type='increase' />
    } else if (change < 0) {
      return <StatArrow type='decrease' />
    }

    return null;
  }

  const getCountChangeDisplayValue = (changeValue) => {
    return `${changeValue > 0 ? '+' : ''}${changeValue} / 24h`
  }

  return (
    <Box maxW='100%' borderWidth='1px' borderRadius='lg' overflow='hidden' margin='5px'>
      <Container maxW='100%' margin='10px'>
        <Center>
          <Avatar backgroundColor='White' marginRight='px' src={props.logo_url} />
          <Box
            mt='1'
            fontWeight='semibold'
            as='h3'
            lineHeight='tight'
            width='90px'
            noOfLines={1}
          >
            {props.display_name}
          </Box>
          <StatGroup width='230px'>
            <Stat marginRight='10px'>
              <StatLabel>Stars</StatLabel>
              <StatNumber>{props.github_stats.stargazers_count.toLocaleString()}</StatNumber>
              <StatHelpText>
                {getStatArrow(props.github_stats.stargazers_count_change)}
                {getCountChangeDisplayValue(props.github_stats.stargazers_count_change)}
              </StatHelpText>
            </Stat>
            <Stat>
              <StatLabel>Subscribers</StatLabel>
              <StatNumber>{props.github_stats.watchers_count.toLocaleString()}</StatNumber>
              <StatHelpText>
                {getStatArrow(props.github_stats.watchers_count_change)}
                {getCountChangeDisplayValue(props.github_stats.watchers_count_change)}
              </StatHelpText>
            </Stat>
          </StatGroup>
        </Center>
      </Container>
    </Box>
  );
}

export default App;
export const STARS_ASC = {
    sortId: 'stars.asc',
    sortText: 'Least Stars',
    sortFn: (a, b) => a.github_stats.stargazers_count > b.github_stats.stargazers_count
};

export const STARS_DESC = {
    sortId: 'stars.desc',
    sortText: '🏆 Most Stars',
    sortFn: (a, b) => a.github_stats.stargazers_count > b.github_stats.stargazers_count
};

export const WATCHERS_ASC = {
    sortId: 'watchers.asc',
    sortText: 'Least Subscribers',
    sortFn: (a, b) => a.github_stats.watchers_count > b.github_stats.watchers_count
};

export const WATCHERS_DESC = {
    sortId: 'watchers.desc',
    sortText: '🏆 Most Subscribers',
    sortFn: (a, b) => a.github_stats.watchers_count > b.github_stats.watchers_count
};

export const STARS_COUNT_CHANGE_ASC = {
    sortId: 'stars-change.asc',
    sortText: 'Least new Stars in 24h',
    sortFn: (a, b) => a.github_stats.stargazers_count_change > b.github_stats.stargazers_count_change
};

export const STARS_COUNT_CHANGE_DESC = {
    sortId: 'stars-change.desc',
    sortText: '🏆 Most Stars in 24h',
    sortFn: (a, b) => a.github_stats.stargazers_count_change > b.github_stats.stargazers_count_change
};

export const WATCHERS_COUNT_CHANGE_ASC = {
    sortId: 'watchers-change.asc',
    sortText: 'Least new Subscribers in 24h',
    sortFn: (a, b) => a.github_stats.watchers_count_change > b.github_stats.watchers_count_change
};

export const WATCHERS_COUNT_CHANGE_DESC = {
    sortId: 'watchers-change.desc',
    sortText: '🏆 Most Subscribers in 24h',
    sortFn: (a, b) => a.github_stats.watchers_count_change > b.github_stats.watchers_count_change
};

export const sort = (a, b, sortOrder, state) => {
    const sortResult = sortOrder.sortFn(state[a[1]], state[b[1]]);
    if (sortOrder.sortId.includes('desc')) {
      return sortResult ? -1 : 1;
    }

    return sortResult ? 1 : -1;
  }
